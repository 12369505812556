import React, { Component } from 'react'
import ReactModal from 'react-modal'
import { Link } from 'gatsby'

import Layout from '../components/layout'

//TODO MIGUEL: Maybe also test Confirmation modal: https://itnext.io/add-confirmation-dialog-to-react-events-f50a40d9a30d
class ModalTest extends Component {
    constructor(props) {
        super(props)
        this.state = {
            isModalOpen: false,
        }
    }
    handleModalOpen = event => {
        console.log('MIGUEL handleModalOpen: ', event);
        this.setState({ isModalOpen: true })
    }

    handleModalClose = event => {
        console.log('MIGUEL handleModalOpen: ', event);
        this.setState({ isModalOpen: false })
    }
    handleSubmit = event => {
        event.preventDefault();
        console.log('MIGUEL handleSubmit: ', event);
        this.handleModalOpen(event);
        console.log('MIGUEL handleSubmit AFTER: ', event);
    }

    render() {
        return (
            <Layout>
                <h1>Testing React Modal</h1>
                <form onSubmit={this.handleSubmit}>
                    <label>
                        Test Form
                        <input />
                    </label>
                    <button type="submit">Send</button>
                    <input type="reset" value="Clear" />
                </form>
                <div id="main">
                    <Link to="/modal/" onClick={this.handleModalOpen}>
                        Click Here
                    </Link>
                </div>
                <ReactModal
                    isOpen={this.state.isModalOpen}
                    onRequestClose={this.handleModalClose}
                    contentLabel="Example Modal In Gatsby"
                >
                    <h2>Thanks for Clicking</h2>
                    <button onClick={this.handleModalClose}>Close Modal</button>
                </ReactModal>
                <br></br>
                <Link to="/">Go to home page</Link>
            </Layout>
        )
    }
}

export default ModalTest
